import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "heading": "Device Agnostic",
  "heading-subtitle": "Choose your device - Spoor supports various tracking hardware so you can choose the one which suits your needs best.",
  "identification": "spoorDevices",
  "deviceTypes": [{
    "image": "/img/ContainerLock.png",
    "name": "Lock Mechanism",
    "publish": true
  }, {
    "image": "/img/Magnet.png",
    "name": "Magnetic Attachment",
    "publish": true
  }, {
    "image": "/img/SolarCharger.png",
    "name": "Solar Powered",
    "publish": true
  }, {
    "image": "/img/AdvancedSensory.png",
    "name": "Advanced Sensors",
    "publish": true
  }]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      